import * as Actions from './logbooks.actions';
import { ILogbook, ILogbooksState, IStateStates } from './logbooks.model';
import { Action, ActionReducer, createReducer, on } from '@ngrx/store';
import { ILogbookMasterDataDetail } from '../settings/logbook-master-data/logbook-master-data.model';
import * as StateActions from '../settings/states/states.actions';

export const logbooksState: ILogbooksState = {
  logbooksDataLoaded: false,
  logbooksDataLoading: false,
  logbooks: [],
  logbookAddLoading: false,
  logbookAddLoaded: false,
  logbookEditLoading: false,
  logbookEditLoaded: false,
  logbooksBulkEditLoading: false,
  logbooksBulkEditLoaded: false,
  workflowDataLoaded: false,
  workflowDataLoading: false,
  logbookFormsLoaded: false,
  logbookFormsLoading: false,
  workflows: [],
  forms: [],
  activeMasterDataId: 0,
  activeMasterDataData: null,
  getActiveMasterDataLoaded: false,
  getActiveMasterDataLoading: false,
  getLogbookMasterDataLoading: false,
  getLogbookMasterDataLoaded: false,
  changeLogbookParent: false,
  changeLogbookCompletedParent: false,
  allFormsLoaded: false,
  allFormsLoading: false,
  getWorkflowDataLoading: false,
  getWorkflowDataLoaded: false,
  checkPassedIssueDateLoading: false,
  checkPassedIssueDateLoaded: false,
  allForms: [],
  logbooksTotal: 0,
  formTotal: 0,
  changeParentLogbook: false,
  changeParentLogbookCompleted: false,
  getLogbookStatesDataLoading: false,
  getLogbookStatesDataLoaded: false,
};

export function logbooksReducer(state: ILogbooksState = logbooksState, baseAction: Action): ILogbooksState {
  const action = baseAction as Actions.LogbooksActions;
  switch (action.type) {
    case Actions.LOGBOOKS_DATA_LOADING:
      return {
        ...state,
        logbooksDataLoading: true,
        logbooksDataLoaded: false,
      };
    case Actions.LOGBOOKS_DATA_LOADED:
      return {
        ...state,
        logbooks: action.payload.data.map((logbook: ILogbook) => {
          return { ...logbook, selectedForms: logbook.selectedForms };
        }),
        logbooksTotal: Number(action.payload.total),
        logbooksDataLoading: false,
        logbooksDataLoaded: true,
      };
    case Actions.ADD_LOGBOOK:
      return {
        ...state,
        logbookAddLoading: true,
        logbookAddLoaded: false,
      };
    case Actions.ADD_LOGBOOK_COMPLETED:
      return {
        ...state,
        logbookAddLoading: false,
        logbookAddLoaded: true,
      };
    case Actions.EDIT_LOGBOOK:
      return {
        ...state,
        logbookEditLoading: true,
        logbookEditLoaded: false,
      };
    case Actions.EDIT_LOGBOOK_COMPLETED:
      return {
        ...state,
        logbookEditLoading: false,
        logbookEditLoaded: true,
      };
    case Actions.GET_LOGBOOK_FORMS:
      return {
        ...state,
        logbookFormsLoading: true,
        logbookFormsLoaded: false,
      };
    case Actions.GET_LOGBOOK_FORMS_COMPLETED:
      return {
        ...state,
        forms: action.payload.data,
        formTotal: Number(action.payload.total),
        logbooksDataLoading: false,
        logbooksDataLoaded: true,
      };
    case Actions.GET_LOGBOOK_MASTER_DATA:
      return {
        ...state,
        getLogbookMasterDataLoading: true,
        getLogbookMasterDataLoaded: false,
      };
    case Actions.GET_LOGBOOK_MASTER_DATA_COMPLETED:
      let activeMasterDataData: ILogbookMasterDataDetail | null = state.activeMasterDataData;
      if (state.activeMasterDataId && action.payload.data.id === state.activeMasterDataId) {
        activeMasterDataData = action.payload.data;
      }

      return {
        ...state,
        activeMasterDataData,
        getLogbookMasterDataLoading: false,
        getLogbookMasterDataLoaded: true,
      };
    case Actions.GET_ACTIVE_LOGBOOK_MASTER_DATA:
      return {
        ...state,
        getActiveMasterDataLoading: true,
        getActiveMasterDataLoaded: false,
      };
    case Actions.GET_ACTIVE_LOGBOOK_MASTER_DATA_COMPLETED:
      return {
        ...state,
        getActiveMasterDataLoading: false,
        getActiveMasterDataLoaded: true,
        activeMasterDataId: action.payload.data[0].id,
      };
    case Actions.CHANGE_LOGBOOK_PARENT:
      return {
        ...state,
        changeParentLogbook: true,
        changeParentLogbookCompleted: false,
      };
    case Actions.CHANGE_LOGBOOK_PARENT_COMPLETED:
      return {
        ...state,
        changeParentLogbookCompleted: true,
        changeParentLogbook: false,
      };
    case Actions.GET_ALL_FORMS:
      return {
        ...state,
        allFormsLoading: true,
        allFormsLoaded: false,
      };
    case Actions.GET_ALL_FORMS_COMPLETED:
      return {
        ...state,
        allForms: action.payload.data,
        allFormsLoading: false,
        allFormsLoaded: true,
      };
    case Actions.CHECK_PASSED_ISSUE_DATE:
      return {
        ...state,
        checkPassedIssueDateLoading: true,
        checkPassedIssueDateLoaded: false,
      };
    case Actions.CHECK_PASSED_ISSUE_DATE_COMPLETED:
      return {
        ...state,
        checkPassedIssueDateLoading: false,
        checkPassedIssueDateLoaded: true,
      };
    case Actions.WORKFLOW_DATA_LOADING:
      return {
        ...state,
        getWorkflowDataLoading: true,
        getWorkflowDataLoaded: false,
      };
    case Actions.WORKFLOW_DATA_LOADED:
      return {
        ...state,
        getWorkflowDataLoading: false,
        getWorkflowDataLoaded: true,
      };
    case Actions.GET_LOGBOOK_STATES_DATA_LOADING:
      return {
        ...state,
        getLogbookStatesDataLoading: true,
        getLogbookStatesDataLoaded: false,
      };
    case Actions.GET_LOGBOOK_STATES_DATA_LOADED:
      return {
        ...state,
        getLogbookStatesDataLoading: false,
        getLogbookStatesDataLoaded: true,
      };
    default:
      return state;
  }
}
