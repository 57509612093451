import { IGenericObject } from '../../shared/model/interface/generic.model';
import { IWorkflow } from '../settings/workflows/workflows.model';
import { IForm } from '../forms/forms.model';
import { ILogbookVersionWithWorkflowDetail } from '../../view/settings/logbook/logbook.model';
import { TColorInput } from '../../shared/component/scw-mat-ui/scw-mat-border-coloring/scw-mat-border-coloring.model';
import { ISelect } from '../../shared/component/scw-mat-ui/scw-mat-select/scw-mat-select.model';
import { ILogbookMasterDataDetail } from '../settings/logbook-master-data/logbook-master-data.model';
import { IIssuer } from '../../shared/component/issuer/issuer.model';

export interface ILogbooksState {
  getWorkflowDataLoading: boolean;
  getWorkflowDataLoaded: boolean;
  checkPassedIssueDateLoading: boolean;
  checkPassedIssueDateLoaded: boolean;
  getLogbookMasterDataLoading: boolean;
  getLogbookMasterDataLoaded: boolean;
  logbooksDataLoaded: boolean;
  logbooksDataLoading: boolean;
  logbooks: ILogbook[];
  logbookAddLoading: boolean;
  logbookAddLoaded: boolean;
  logbookEditLoading: boolean;
  logbookEditLoaded: boolean;
  logbooksBulkEditLoading: boolean;
  logbooksBulkEditLoaded: boolean;
  workflowDataLoaded: boolean;
  workflowDataLoading: boolean;
  logbookFormsLoaded: boolean;
  logbookFormsLoading: boolean;
  workflows: IWorkflow[];
  forms: any[];
  activeMasterDataId: number;
  activeMasterDataData: ILogbookMasterDataDetail | null;
  getActiveMasterDataLoaded: boolean;
  getActiveMasterDataLoading: boolean;
  changeLogbookParent: boolean;
  changeLogbookCompletedParent: boolean;
  allFormsLoaded: boolean;
  allFormsLoading: boolean;
  allForms: IForm[];
  logbooksTotal: number;
  formTotal: number;
  changeParentLogbook: boolean;
  changeParentLogbookCompleted: boolean;
  getLogbookStatesDataLoading: boolean;
  getLogbookStatesDataLoaded: boolean;
}

export interface ILogbookTableQuery {
  page: number;
  rowsPerPage: number;
  search?: string;
  sort?: {
    type: string;
    column: string;
  };
  isActive?: boolean;
  favoritesOnly?: boolean;
}

export interface ILogbook {
  id: number;
  logbookId: string;
  legacyId: string | null;
  logbookVersionId: number;
  name: string;
  parentLogbook: number;
  parentLogbookId: number;
  parentLogbookLogbookId: string;
  parentLogbookName: string;
  mainLogbook: boolean;
  isMobileLogbook: boolean;
  approvalStepPosition: number;
  approveCompleted: boolean;
  versionNumber: number;
  issuedDate: string;
  activatedAt: string;
  logbookVersionApproveCompleted: boolean;
  logbookApproveCompleted: boolean;
  approvedByUsername: string;
  approvedByFirstName: string;
  approvedByLastName: string;
  approvedByEmail: string;
  approvedByTitle: string;
  approvalDate: string;
  isActive: boolean;
  createdAt: string;
  createdByUsername: string;
  createdByFirstName: string;
  createdByLastName: string;
  createdByEmail: string;
  createdByTitle: string;
  meta: IGenericObject<any>;
  isArchived: boolean;
  archiveInProgress: boolean;
  loggable: boolean;
  logbookMasterDataId: number;
  logbookWorkflowId: number;
  selectedForms: any;
  toBeArchived: boolean;
  hasChildren: boolean;
  orderBy: number;
  currentOngoingCount: number;
  currentRejectedCount: number;
  workflowId: number;
  fieldVersion: number;
  fieldMeta: any;
  remainingSentBackCount?: number;
  logbookStatesCount: number;
  states?: IStates[] | [];
  stateDuration: string;
  stateDurationType: number;
}

export interface ILogbookDetail {
  activatedAt: string;
  approvalDate: string;
  approvalStepPosition: number;
  approvals: any;
  approveCompleted: boolean;
  approvedBy: number;
  approvedRole: number;
  clientId: number;
  createdAt: string;
  createdBy: number;
  deletedById: null;
  id: number;
  isArchived: boolean;
  isSubmitted: boolean;
  isSubmittedOnce: boolean;
  logbookId: string;
  submittedAt: string;
  toBeArchived: boolean;
  updatedAt: string;
  workflow: number;
}

export interface ILogbookSettings {
  workflows: IWorkflow[];
  forms: IForm[];
  logbooks: ILogbook[];
  logbookVersionData: ILogbookVersionWithWorkflowDetail;
  previousVersionData: ILogbookVersionWithWorkflowDetail;
}

export interface ILogbookFormatted extends ILogbook {
  loggableFormatted: string;
  issueDateFormatted: string;
  stateDurationFormatted: string;
  borderColoring: IBorderColoring;
  logbookStatesCountColumn: ILogbookStatesCountColumn;
  actionColumn: IActionColumn;
  input: {
    inputConfiguration: {
      inputModel: number;
    };
  };
}

export interface IBorderColoring {
  approvalStepPosition: IApproveStatus;
}

export interface IActionColumn {
  actionColumn: IActionColumnData;
}

export interface IActionColumnData {
  type: string;
  size: string;
  translation: string;
  onClick: Function;
}

export interface ILogbookStatesCountColumn {
  logbookStatesCountColumn: ILogbookStatesCountColumnData;
}

export interface ILogbookStatesCountColumnData {
  type: string;
  size: string;
  translation: string;
  onClickShowStatePopOver: Function;
  onClickShowStateEdit: Function;
}

export interface ILogbookStates {
  id?: number | null;
  logbookId?: string | null;
  states: IStates[] | [];
}

export interface IStateStates {
  statesListDataLoading: boolean;
  StatesListDataLoaded: boolean;
  data: IStates[] | [];
}

export interface IStates {
  id: number;
  name: string;
  hexColorCode: string;
}

export interface IApproveStatus {
  type: TColorInput;
  text: string;
}

export interface ILogbookExcelContent {
  activeMasterDataData: ILogbookMasterDataDetail | null;
  workflows: IWorkflow[] | null;
  logbooks: ILogbook[] | null;
  forms: IForm[] | null;
  yesNo: ISelect<number | string, string>[] | null;
  yesDropdown: ISelect<number | string, string>[] | null;
  timeTypeDropdown: ISelect<number | string, string>[] | null;
}

export interface ILogbookExcel {
  isMainLogbookData: ISelect<number, string>[];
  isMobileLogbookData: ISelect<number, string>[];
  parentLogbookData: ISelect<number, string>[];
  workflowData: ISelect<string, string>[];
  logbookData: ILogbookExcelData[];
  fieldVersion: number | null;
  stateDurationTypeData: ISelect<number, string>[];
}

export interface ILogbookExcelPreDefined {
  isMainLogbook: number;
  mainLogbook: number;
  isMobileLogbook: number;
  issuedDate: string;
  legacyId: string;
  name: string;
  order: number;
  parentLogbook: number;
  selectedForms: string | number[];
  workflow: string;
  isMainLogbookDropdown: ISelect<number, string>;
  isMobileLogbookDropdown: ISelect<number, string>;
  parentLogbookDropdown: ISelect<number, string>;
  approvalWorkflowDropdown: ISelect<number, string>;
  workflowDropdown: ISelect<number, string>;
  fieldMeta: any[];
  stateDuration: string;
  stateDurationType: number;
}

export interface ILogbookExcelUserDefined {
  [key: string]: any | any[] | object;
}

export interface ILogbookExcelData extends ILogbookExcelUserDefined, ILogbookExcelPreDefined {}

export type MasterDataLocationType = 'all' | 'logbook' | 'allLogbookVersion' | 'home';
export type LogbookAndLogbookVersionLocationType = 'logbook' | 'allLogbookVersion';

export interface ILogbookExcelWithError extends ILogbookExcelData {
  errors: any[];
  success?: boolean;
}

export interface IFormSubmissionRequestBody {
  issuer: IIssuer | null;
  payload: object;
}

export interface IGetLogbookVersionsParams extends ILogbookTableQuery {
  logbookId: number | null;
}

export interface IGetLogbookHistoryParams {
  logbookId?: number;
  versionIds?: number[];
}

export enum ELogbookAndLogbookVersionTabs {
  LOGBOOK = 'Logbook',
  ALL_LOGBOOK_VERSION = 'All Logbook Version',
}
