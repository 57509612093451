import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, of, switchMap, tap } from 'rxjs';
import * as AppActions from '../../app/actions';
import { Store } from '@ngrx/store';
import * as LogbookAppReducer from '../../logbook.reducer';
import {
  IBaseOneResponse,
  IBulkResponseRecord,
  IGetManyResponse,
} from '../../../shared/model/interface/crud-response-interface.model';
import { StatesService } from './states.service';
import { HttpParams } from '@angular/common/http';
import * as StateActions from './states.actions';
import { ServiceUtilities } from '../../../shared/helper/service-utilities';
import { IIssuer } from '../../../shared/component/issuer/issuer.model';
import { IAddState, IBulkEditStates, IState, IStateTableQuery } from './states.model';
import { IIssuerAndReason } from '../../../shared/component/before-action-preparer/before-action-preparer.model';

@Injectable()
export class StatesEffects {
  constructor(
    private readonly actions$: Actions,
    private readonly service: StatesService,
    private readonly store: Store<LogbookAppReducer.LogbookAppState>,
  ) {}

  getStateData = createEffect(() =>
    this.actions$.pipe(
      ofType(StateActions.ActionTypes.STATES_DATA_LOADING),
      switchMap((payload: { tableQuery: IStateTableQuery }) => {
        this.store.dispatch(new AppActions.ShowLoader());

        const body: Record<string, string | number> = ServiceUtilities.prepareGenericBodyForRequest(payload.tableQuery);
        return this.service.getStates(body).pipe(
          tap(() => {
            this.store.dispatch(new AppActions.HideLoader());
          }),
          map((response: IGetManyResponse<IState>) => StateActions.statesDataLoaded({ payload: response })),
          catchError((error) => of(StateActions.fetchError({ error }))),
        );
      }),
      catchError((error) => of(StateActions.fetchError({ error }))),
    ),
  );

  addState = createEffect(() =>
    this.actions$.pipe(
      ofType(StateActions.ActionTypes.ADD_STATE),
      switchMap((payload: { state: IAddState; issuer: IIssuer | null }) => {
        this.store.dispatch(new AppActions.ShowLoader());

        return this.service.addState(payload.state, payload.issuer).pipe(
          tap(() => {
            this.store.dispatch(new AppActions.HideLoader());
          }),
          map((response: IBaseOneResponse<IState>) => StateActions.addStateCompleted({ payload: response.data })),
          catchError((error) => of(StateActions.fetchError({ error }))),
        );
      }),
      catchError((error) => of(StateActions.fetchError({ error }))),
    ),
  );

  editState = createEffect(() =>
    this.actions$.pipe(
      ofType(StateActions.ActionTypes.EDIT_STATE),
      switchMap((payload: { state: IAddState; id: number; issuerAndReason: IIssuerAndReason }) => {
        this.store.dispatch(new AppActions.ShowLoader());

        return this.service.editState(payload.state, payload.id, payload.issuerAndReason).pipe(
          tap(() => {
            this.store.dispatch(new AppActions.HideLoader());
          }),
          map((response: IBaseOneResponse<IState>) => StateActions.editStateCompleted({ payload: response.data })),
          catchError((error) => of(StateActions.fetchError({ error }))),
        );
      }),
      catchError((error) => of(StateActions.fetchError({ error }))),
    ),
  );

  bulkEditStates = createEffect(() =>
    this.actions$.pipe(
      ofType(StateActions.ActionTypes.BULK_EDIT_STATES),
      switchMap((payload: { states: IBulkEditStates[]; issuerAndReason: IIssuerAndReason }) => {
        this.store.dispatch(new AppActions.ShowLoader());

        return this.service.bulkEditStates(payload.states, payload.issuerAndReason).pipe(
          tap(() => {
            this.store.dispatch(new AppActions.HideLoader());
          }),
          map((response: IGetManyResponse<IBulkResponseRecord<IState>>) =>
            StateActions.bulkEditStatesCompleted({ payload: response.data }),
          ),
          catchError((error) => of(StateActions.fetchError({ error }))),
        );
      }),
      catchError((error) => of(StateActions.fetchError({ error }))),
    ),
  );

  deleteState = createEffect(() =>
    this.actions$.pipe(
      ofType(StateActions.ActionTypes.DELETE_STATE),
      switchMap((payload: { id: number; issuerAndReason: IIssuerAndReason }) => {
        this.store.dispatch(new AppActions.ShowLoader());

        return this.service.deleteState([payload.id], payload.issuerAndReason).pipe(
          tap(() => {
            this.store.dispatch(new AppActions.HideLoader());
          }),
          map((response: IGetManyResponse<IBulkResponseRecord<IState>>) =>
            StateActions.deleteStateCompleted({ payload: response.data[0].data[0] }),
          ),
          catchError((error) => of(StateActions.fetchError({ error }))),
        );
      }),
      catchError((error) => of(StateActions.fetchError({ error }))),
    ),
  );

  bulkDeleteActivityType = createEffect(() =>
    this.actions$.pipe(
      ofType(StateActions.ActionTypes.BULK_DELETE_STATE),
      switchMap((payload: { stateIds: number[]; issuerAndReason: IIssuerAndReason }) => {
        this.store.dispatch(new AppActions.ShowLoader());

        return this.service.deleteState(payload.stateIds, payload.issuerAndReason).pipe(
          tap(() => {
            this.store.dispatch(new AppActions.HideLoader());
          }),
          map((response: IGetManyResponse<IBulkResponseRecord<IState>>) =>
            StateActions.bulkDeleteStateCompleted({ payload: response.data }),
          ),
          catchError((error) => of(StateActions.fetchError({ error }))),
        );
      }),
      catchError((error) => of(StateActions.fetchError({ error }))),
    ),
  );
}
